import React, { useEffect, useState } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "store/slices/profileSlice";
import { getSettings } from "store/slices/settingSlice";
import { getProfileDetails } from "services/appServices/ProfileService";
import { loadSettingDetails } from "services/appServices/siteSettingService";

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {
  const dispatch = useDispatch();
  const [resData, setResData] = useState({});
  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  // const profile = useSelector((state) => state?.profile?.pofileData);

  //profile details api call

  useEffect(() => {
    getProfileDetails().then((res) => {
      dispatch(setProfileDetails(res?.data?.data));
      // console.log(res?.data?.data, "ssssssss");
    });
  }, []);

  const settings = useSelector((state) => state?.settings?.data);
  useEffect(() => {
    loadSettingDetails(setResData).then((res) => {
      dispatch(getSettings(res?.data?.data));
    });
  }, []);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon || resData?.favicon;
  }
  favIconChange();

  return <Component {...props} />;
};

export default AppRoute;
