import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login")),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() => import("views/auth-views/authentication/login-1")),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() => import("views/auth-views/authentication/login-2")),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() => import("views/auth-views/authentication/register-1")),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() => import("views/auth-views/authentication/register-2")),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("views/auth-views/authentication/forgot-password")),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "admins.list",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminList")),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminAdd")),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() => import("views/app-views/pages/setting/EditProfile")),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/admin/admin-edit")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() => import("views/app-views/pages/setting/ChangePassword")),
  },

  // Usersection route
  {
    key: "users.list",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/apps/user/userList")),
  },
  {
    key: "users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/apps/user/userAdd")),
  },
  {
    key: "users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/user/userEdit")),
  },

  //profile route
  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import("views/app-views/pages")),
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile")),
  },
  {
    key: "pages.invoice",
    path: `${APP_PREFIX_PATH}/pages/invoice`,
    component: React.lazy(() => import("views/app-views/pages/invoice")),
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
  },

  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/dashboard/view-dashboard/")),
  },

  // cms
  {
    key: "cms.page-list",
    path: `${APP_PREFIX_PATH}/page-list`,
    component: React.lazy(() => import("views/app-views/apps/page/pageList")),
  },
  {
    key: "cms.page-add",
    path: `${APP_PREFIX_PATH}/page-add`,
    component: React.lazy(() => import("views/app-views/apps/page/pageAdd")),
  },
  {
    key: "cms.page-edit",
    path: `${APP_PREFIX_PATH}/page-edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/page/pageEdit")),
  },
  {
    key: "cms.site-settings",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() => import("views/app-views/apps/settings")),
  },
];
