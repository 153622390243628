import { apiRequest } from "utils/async/apiUtils";

export const authLogin = async (params) => {
  try {
    const res = await apiRequest("post", "login", {
      body: {
        email: params.email,
        password: params.password,
      },
    });
    const data = res.data;
    return data;
  } catch (err) {
    throw new Error(err.data.message);
  }
};

export const verifyToken = async (setAuth = () => {}) => {
  try {
    setAuth({ loaded: false, authenticated: false });

    const res = await apiRequest("post", "verify-token", {});

    const data = res.data;

    setAuth({ loaded: true, authenticated: true });

    // return data;
  } catch (err) {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    window.location.href = "/auth/login";

    throw new Error(err.message);
  }
};

export const sendEmail = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/send-otp"}`,
      {
        body: {
          ...params,
        },
      },
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const verifyOtp = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/verify-otp"}`,
      {
        body: {
          ...params,
        },
      },
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const resetPassword = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/reset-password"}`,
      {
        body: {
          ...params,
        },
      },
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};


