import { apiRequest } from "utils/async/apiUtils";

export const getProfileDetails = async (setResData = () => {}, setLoaded = () => {}) => {
  try {
    const res = await apiRequest("get", "profile/details");

    const data = res.data;
    // console.log(res, "resss");
    if (res?.data?.success) setResData(res.data.docs);

    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const changePassword = async (data) => {
  try {
    const res = await apiRequest("put", "profile/change-password", {
      body: {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      },
    });
    return res;
  } catch (error) {
    return error.data;
  }
};

export const addProfile = async (params) => {
  try {
    // console.log(id, params, "params");
    const res = await apiRequest(
      "patch",
      "user/edit-profile",
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );

    // const data = res.data.data;

    return res;
  } catch (err) {
    throw new Error(err);
  }
};
